import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';
import { MESSAGE } from '../../../global-constants';

export const ClientListing = createAsyncThunk(
    '/api/client-listing',
    async ({ pageNo, itemsPerPage, searchParam, dateRange, filterQuery, sort }, { rejectWithValue }) => {
        try {
            const startDateQuery = dateRange.startDate ? `&start_date=${dateRange.startDate}` : '';
            const endDateQuery = dateRange.endDate ? `&end_date=${dateRange.endDate}` : '';
            const response = await api.post(
                `${Endpoints.allClientsListUrl}?q=${encodeURIComponent(
                    searchParam
                )}${startDateQuery}${endDateQuery}&limit=${itemsPerPage}&page=${pageNo}`,
                {
                    filters: filterQuery
                        ? filterQuery.templateType
                            ? {
                                  template_type: [filterQuery.templateType],
                                  survey_count: filterQuery.surveyCount,
                              }
                            : {
                                  survey_count: filterQuery.surveyCount,
                              }
                        : {},
                    sort: sort,
                },
                {
                    headers: { accept: 'application/json' },
                }
            );

            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const SurveyTemplatesListing = createAsyncThunk('/api/survey-templates', async (params, { rejectWithValue }) => {
    try {
        const response = await api.get(
            Endpoints.surveyTemplateListingUrl.replace('__limit__', params.limit).replace('__page__', params.page)
        );
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const OpportunityLogsListing = createAsyncThunk(
    '/api/salesforce-info-logs',
    async (params, { rejectWithValue }) => {
        try {
            const { client_id } = params;
            const url = `${Endpoints.getOpportunityLogs}`;
            const response = await api.get(url, { params: { client_id } });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const ValidateOpportunity = createAsyncThunk('/api/salesforce-info', async (params, { rejectWithValue }) => {
    try {
        const { opportunity_name } = params;
        const url = `${Endpoints.searchOpportunity}/${opportunity_name}`;
        const response = await api.get(url);
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const UpdateLegalCheckDatabase = createAsyncThunk(
    '/api/salesforce-info',
    async (params, { rejectWithValue }) => {
        try {
            const { opportunity_id } = params;
            const url = `${Endpoints.searchOpportunity}/${opportunity_id}`;
            const response = await api.put(url);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const DeleteOpportunity = createAsyncThunk(
    '/api/salesforce-info-delete',
    async (params, { rejectWithValue }) => {
        try {
            const { salesforce_id } = params;
            const url = `${Endpoints.deleteOpportunity}`;
            const response = await api.delete(url, { data: { salesforce_id: [salesforce_id] } });
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const IndustryListing = createAsyncThunk(`${Endpoints.industryUrl}`, async (params, { rejectWithValue }) => {
    try {
        const response = await api.get(
            Endpoints.industryUrl.replace('__limit__', params.limit).replace('__page__', params.page)
        );
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const ExtraAttributes = createAsyncThunk(
    `${Endpoints.extraAttributesUrl}`,
    async (params, { rejectWithValue }) => {
        try {
            const { template_id } = params;
            const response = await api.get(`${Endpoints.extraAttributesUrl}?template_id=${template_id}`);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const AddClient = createAsyncThunk(
    `${Endpoints.addClient}`,
    async (reqParams, { rejectWithValue, dispatch }) => {
        const { resetSaved } = await import('../../reducers/slice/ClientSlice');
        try {
            const { client_name, description, meta_info, salesforce_data = [] } = reqParams;
            const response = await api.post(Endpoints.addClient, {
                client_name,
                description,
                meta_info,
            });
            const { data } = response;
            const {
                data: { uuid: client_id },
            } = data;
            if (salesforce_data && salesforce_data?.length && client_id) {
                await dispatch(LinkOpportunityWithClient({ salesforce_data, client_id })).unwrap();
            }
            await dispatch(resetSaved());
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const GetClientById = createAsyncThunk(Endpoints.clientDetails, async (params, { rejectWithValue }) => {
    try {
        const { clientId } = params;
        const response = await api.get(Endpoints.clientDetails.replace(':client_id', clientId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const UpdateClientDetails = createAsyncThunk(
    `update_client_with_id`,
    async (params, { rejectWithValue, dispatch }) => {
        const { resetSaved } = await import('../../reducers/slice/ClientSlice');
        try {
            const { clientId, metaInfo } = params;
            const { salesforce_data } = metaInfo;
            const payload = { ...metaInfo };
            delete payload.salesforce_data;

            const response = await api.put(Endpoints.clientDetails.replace(':client_id', clientId), payload);
            const { data } = response;
            if (salesforce_data && salesforce_data?.length) {
                await dispatch(LinkOpportunityWithClient({ salesforce_data, client_id: clientId })).unwrap();
            }
            await dispatch(resetSaved());
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const LinkOpportunityWithClient = createAsyncThunk(
    `${Endpoints.saveSalesforceInfo}`,
    async (reqParams, { rejectWithValue }) => {
        try {
            const { salesforce_data, client_id } = reqParams;
            const payload = {
                client_id: client_id,
                salesforce_data,
            };
            const response = await api.post(Endpoints.saveSalesforceInfo, payload);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const DeleteClient = createAsyncThunk(
    `${Endpoints.addClient}/delete`,
    async (reqParams, { rejectWithValue, dispatch }) => {
        const { openPopUp } = await import('../../reducers/slice/SnackbarSlice');
        try {
            const url = `${Endpoints.addClient}/${reqParams.ids}`;
            const response = await api.delete(url);
            const { data } = response;
            const params = {
                pageNo: reqParams.clientFilter.page,
                itemsPerPage: reqParams.clientFilter.limit,
                searchParam: reqParams.clientFilter.search,
                dateRange: reqParams.clientFilter.dateRange,
                filterQuery: reqParams.clientFilter.filter,
                sort: reqParams.clientFilter.sort,
            };
            await dispatch(ClientListing(params));
            await dispatch(openPopUp(true));
            return data;
        } catch (error) {
            dispatch(openPopUp(false));
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const BulkDeleteClient = createAsyncThunk(
    `${Endpoints.bulkDeleteClientUrl}`,
    async (reqParams, { rejectWithValue, dispatch }) => {
        const { client_ids, clientFilter } = reqParams;
        const { page, limit, dateRange, filter, sort, search } = clientFilter;
        const { openPopUp } = await import('../../reducers/slice/SnackbarSlice');
        try {
            const response = await api.post(Endpoints.bulkDeleteClientUrl, { client_ids });
            const { data } = response;
            const params = {
                pageNo: page,
                itemsPerPage: limit,
                searchParam: search,
                dateRange: dateRange,
                filterQuery: filter,
                sort: sort,
            };
            await dispatch(ClientListing(params));
            await dispatch(openPopUp(true));
            return data;
        } catch (error) {
            dispatch(openPopUp(false));
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);
