import { createSlice } from '@reduxjs/toolkit';
import { getGlossary, updateGlossary, addCustomGlossary } from '../../actions/Surveys/action';

export const INITIAL_STATE = {
    loading: false,
    isUpdated: false,
    error: false,
    errorMessage: '',
    successMessage: '',
    glossaries: { CUSTOM: { records: [] }, STANDARD: { records: [] } },
};

const SurveyOverview = createSlice({
    initialState: INITIAL_STATE,
    name: 'SurveyOverview',
    extraReducers: builder => {
        builder
            .addCase(getGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(getGlossary.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.glossaries = payload;
            })
            .addCase(getGlossary.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(updateGlossary.fulfilled, (state, { payload }) => {
                const { update_obj } = payload;
                state.loading = false;
                state.isUpdated = true;
                if (update_obj?.field === 'order') {
                    state.successMessage = 'Glossary reordered successfully';
                } else if (update_obj?.field === 'is_deleted') {
                    state.successMessage = 'Glossary deleted successfully';
                } else {
                    state.successMessage = 'Glossary updated successfully';
                }
            })
            .addCase(updateGlossary.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(addCustomGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(addCustomGlossary.fulfilled, (state, { payload }) => {
                const { data, index } = payload;
                state.loading = false;
                state.isUpdated = true;
                state.successMessage = 'Glossary updated successfully';
                state.glossaries['CUSTOM'].records[index].uuid = data.uuid;
                state.glossaries['CUSTOM'].records[index].is_active = data.is_active;
                delete state.glossaries['CUSTOM'].records[index].local;
                delete state.glossaries['CUSTOM'].records[index].is_edited;
            })
            .addCase(addCustomGlossary.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        reset: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = '';
            state.successMessage = '';
        },
        updateStandardGlossary: (state, { payload }) => {
            const { uuid, update_obj } = payload;
            const recordIndex = state.glossaries['STANDARD'].records.findIndex(el => el.uuid === uuid);
            state.glossaries['STANDARD'].records[recordIndex][update_obj.field] = update_obj.value;
        },
        setCustomGlossary: (state, { payload }) => {
            state.glossaries['CUSTOM'].records = payload;
        },
    },
});

export const { reset, updateStandardGlossary, setCustomGlossary } = SurveyOverview.actions;
export default SurveyOverview.reducer;
