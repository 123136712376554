import { createSlice } from '@reduxjs/toolkit';
import {
    getTextEditTabs,
    getTextEditTabsData,
    updateSystemMessages,
    updateInstructionTextPage,
    updateInstructionsTextSubGroup,
    updateQuestionModulesQuestion,
} from '../../actions/TextEdit/actions';

export const INITIAL_STATE = {
    loading: false,
    scale: [],
    isUpdated: false,
    error: false,
    errorMessage: '',
    tabs: {},
    tabsData: {},
};

const TextEdit = createSlice({
    initialState: INITIAL_STATE,
    name: 'TextEdit',
    extraReducers: builder => {
        builder
            .addCase(getTextEditTabs.pending, state => {
                state.loading = true;
            })
            .addCase(getTextEditTabs.fulfilled, (state, { payload }) => {
                state.tabs = payload.data;
                if (Object.keys(payload.data).length === 0) {
                    state.loading = false;
                }
            })
            .addCase(getTextEditTabs.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getTextEditTabsData.pending, state => {
                state.loading = true;
            })
            .addCase(getTextEditTabsData.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.tabsData = payload.data;
            })
            .addCase(getTextEditTabsData.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateSystemMessages.pending, state => {
                state.loading = true;
            })
            .addCase(updateSystemMessages.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateSystemMessages.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateInstructionTextPage.pending, state => {
                state.loading = true;
            })
            .addCase(updateInstructionTextPage.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateInstructionTextPage.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateInstructionsTextSubGroup.pending, state => {
                state.loading = true;
            })
            .addCase(updateInstructionsTextSubGroup.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateInstructionsTextSubGroup.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateQuestionModulesQuestion.pending, state => {
                state.loading = true;
            })
            .addCase(updateQuestionModulesQuestion.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateQuestionModulesQuestion.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        reset: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = false;
        },
        resetTabs: state => {
            state.tabs = {};
            state.tabsData = {};
        },
    },
});

export const { reset, resetTabs } = TextEdit.actions;
export default TextEdit.reducer;
