import { Chip } from '@mui/material';
import React from 'react';
import { pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel, Img } from '../../../../common/FormInputs/Common';
import { FlexStartGap } from '../../../../common/styled/styled';
import { full_surveyType, pulse_surveyType, surveyTypes } from '../../../../global-constants';
import Text from '../../../KnowledgeCenter/Text';

export const Badge = ({ data = [], survey_template, survey_sub_type = '' }) => {
    return (
        <Chip
            size="small"
            color="primary"
            label={
                <CommonLabel
                    textSize={12}
                    textWeight={500}
                    lineHeight={16}
                    label={
                        data?.length
                            ? `${
                                  survey_sub_type === surveyTypes[0]
                                      ? full_surveyType
                                      : survey_sub_type === surveyTypes[1]
                                      ? pulse_surveyType
                                      : ''
                              } ${data?.find(({ uuid }) => uuid === survey_template)?.template_name}` || '--'
                            : 'NA'
                    }
                />
            }
            sx={{
                '.MuiChip-label': {
                    paddingLeft: '0',
                    paddingRight: '0',
                },
                background: '#0679C3',
                padding: `0 ${pxToVw(7)}vw !important`,
                height: '3vh',
            }}
        />
    );
};

export const StatusBadge = ({ src, label, color, bg, cursor = 'default' }) => {
    return (
        <Chip
            size="small"
            label={
                <FlexStartGap
                    gap={pxToVw(4)}
                    style={{
                        alignItems: 'center',
                    }}
                >
                    {src ? <Img image={src} height={pxToVh(12)} width={pxToVw(12)} alt="classname" /> : null}

                    <Text fontColor={color} fontSize="12px" lineHeight={16} label={label} fontWeight={500} />
                </FlexStartGap>
            }
            sx={{
                '.MuiChip-label': {
                    paddingLeft: '0',
                    paddingRight: '0',
                },
                background: bg,
                padding: `${pxToVh(2)}vh ${pxToVw(8)}vw !important`,
                cursor: cursor,
            }}
        />
    );
};
